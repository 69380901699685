(function ($) {
    'use strict';

    var $toolTableWrapper = $('#tool-table-wrapper');

    function pollTable() {
        setTimeout(function() {
            $.ajax({
                url: laroute.route('tool.analyzes_table'),
                type: 'GET',
                complete: pollTable,
                success: function (content) {
                    $toolTableWrapper.html(content);
                }
            })
        }, 1000);
    }

    function Tool() {
        pollTable();
        this.handleFormSubmit($('#tool-form'));
    }

    Tool.prototype.handleFormSubmit = function ($form) {
        var ladda = Ladda.create(document.querySelector('#tool-submit'));

        $form.submit(function (e) {
            var url = $form.attr('action');
            ladda.start();

            $.ajax({
                type: $form.attr('method'),
                url: url,
                data: $form.serialize(),
                success: function (data) {
                    ladda.stop();

                    generateNotification('Domains sucessfully sent for processing', 'success');

                    $('#domains').val('');
                }
            });

            e.preventDefault();

            return false;
        });
    };

    window.Tool = Tool;

})(jQuery);
