(function ($) {
    'use strict';

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    function generateNotification(text, type) {
        return noty({
            text        : text,
            type        : type,
            dismissQueue: false,
            layout      : 'topRight',
            theme       : 'bootstrapTheme'
        });
    }

    window.generateNotification = generateNotification;

})(jQuery);
